<template>
  <router-view :style="{ 'font-family': fontByLanguage }" />
</template>
<script>
import { core } from "./config/pluginInit"
import axios from "axios"
import ApiService from "./services/api"

export default {
  name: "App",
  data() {
    return {
      coords: {
        latitude: null,
        longitude: null,
      },
    }
  },

  methods: {
    getTokenFromUrl() {
      const query = new URLSearchParams(window.location.search)
      return query.get("token")
    },

    checkTokenAndRequest() {
      const token = this.getTokenFromUrl()
      if (token) {
        this.sendTokenToEndpoint(token)
      }
    },

    async sendTokenToEndpoint(token) {
      localStorage.setItem("MyReligion", token)
      await ApiService.olimob_send_token(token)
        .then((response) => {
          ApiService.headers = {
            Authorization: "Bearer " + token,
            lang: localStorage.getItem("lang"),
          }
          this.$router.push("/")

          // localStorage.setItem("MyReligion", response.data.access_token)
          // this.$router.push('/')
        })
        .catch((error) => {
          console.log(error)
        })
    },
  },

  mounted() {
    this.checkTokenAndRequest()
    core.mainIndex()
    console.log(localStorage.getItem("MyReligion"))
  },
  computed: {
    fontByLanguage() {
      if (this.$i18n.locale === "ku" || this.$i18n.locale === null) {
        return "Speda, Arial, sans-serif"
      } else if (this.$i18n.locale === "ar") {
        return "Janna-LT, Arial, sans-serif"
      } else {
        return "Roboto, sans-serif"
      }
    },
  },
}
</script>
<style lang="scss">
:root {
  --plyr-color-main: #bca467 !important;
}

body {
  overflow-x: hidden !important;
  overflow-y: auto !important;
}

@import "assets/scss/style.scss";

@font-face {
  font-family: "MyriadPro-Bold";
  src: url("./assets/fonts/MYRIADPRO-BOLD.OTF") format("opentype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Janna-LT";
  src: url("./assets/fonts/Janna-LT.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Speda";
  src: url("./assets/fonts/Speda-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

// change player color
// .plyr--full-ui.plyr--video .plyr__control--overlaid {
//   background: #BCA467 !important;
// }

// .plyr--video .plyr__control:hover{
//   background: #BCA467 !important;
// }

// .plyr--full-ui input[type=range] {
//   color: #BCA467 !important;
// }

// .plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]::before {
//   color: #BCA467 !important;
// }
</style>
